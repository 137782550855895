<script setup lang="ts">
import { Btn } from 'webcc-ui-components'

const props = withDefaults(
  defineProps<{
    total: number
    rest: number
    disabled?: boolean
  }>(),
  {
    disabled: false,
    loading: false,
  },
)

defineEmits<{
  (e: 'load-more'): void
}>()

const { t } = useI18n()
const paginationTitle = computed(() =>
  t('components.bookings.viewingItemsPerPage', {
    itemsPerPage: props.total - props.rest,
    allItems: props.total,
  }),
)
const isRestNotZero = computed(() => props.rest !== 0)
const hasLoadMore = computed(
  () =>
    Boolean(getCurrentInstance()?.vnode.props?.onLoadMore) &&
    isRestNotZero.value,
)
</script>

<template>
  <div data-id="basic-pagination" class="flex flex-col items-center gap-y-2">
    <p class="text-base font-normal leading-4 text-txt-300">
      {{ paginationTitle }}
    </p>
    <Btn
      v-if="hasLoadMore"
      outlined
      variant="neutral"
      :disabled="disabled"
      @click="$emit('load-more')"
    >
      <span class="text-base font-medium leading-5 text-txt-400">
        {{ $t('components.actions.loadMoreResults') }}
      </span>
    </Btn>
  </div>
</template>
